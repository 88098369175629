import React from 'react'
import { Layout, Pagination, Posts, SEO } from '../components'
import { graphql } from 'gatsby'

const PostListTemplate = props => {
  const {
    data: {
      allMdx: { nodes: posts },
    },
  } = props
  const { currentPostPage, numPostPages } = props.pageContext

  const isFirst = currentPostPage === 1
  const isLast = currentPostPage === numPostPages

  const previousPostPage =
    currentPostPage - 1 === 1
      ? '/blog/posts/'
      : `/blog/posts/page/${(currentPostPage - 1).toString()}/`
  const nextPostPage = `/blog/posts/page/${(currentPostPage + 1).toString()}/`

  const currentPageTitle = '글'

  return (
    <Layout>
      <SEO title={`${currentPageTitle} - page ${currentPostPage}`} />
      <Pagination
        division="post"
        isFirst={isFirst}
        isLast={isLast}
        prevPage={previousPostPage}
        nextPage={nextPostPage}
        numPages={numPostPages}
      />
      {posts.length > 0 ? (
        <Posts posts={posts} title={currentPageTitle} />
      ) : null}
    </Layout>
  )
}

export const postListQuery = graphql`
  query postListQuery($skip: Int, $limit: Int) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { division: { eq: "post" }, published: { eq: true } }
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        excerpt(pruneLength: 100, truncate: true)
        frontmatter {
          title
          author
          division
          category
          subcategory
          tags
          date(formatString: "YYYY-MM-DD")
          slug
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        id
        timeToRead
      }
    }
  }
`

export default PostListTemplate
